<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-select v-model="dataForm.msgType" placeholder="消息类型" clearable>
          <el-option
            v-for="(val,key) in typeDict"
            :key="key"
            :label="val"
            :value="key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="dataForm.msgStatus" placeholder="消息状态" clearable>
          <el-option
            v-for="(val,key) in statusOptions"
            :key="key"
            :label="val.label"
            :value="parseInt(key)">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="dataForm.dateRange"
          type="daterange"
          unlink-panels
          :clearable="true"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
        <el-button type="success" @click="allMarkAsRead()" :disabled="this.unRead === 0">全部标记为已读</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="100">
      </el-table-column>
      <el-table-column
        prop="msgType"
        header-align="center"
        align="center"
        label="消息类型">
        <template slot-scope="scope">
          <el-tag type="success">{{ typeDict[scope.row.msgType] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="msgName"
        header-align="center"
        align="center"
        label="消息名称">
      </el-table-column>
      <el-table-column
        prop="msgObj"
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="消息内容">
      </el-table-column>
      <el-table-column
        prop="msgStatus"
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <el-tag :type="statusOptions[scope.row.msgStatus].tag">{{ statusOptions[scope.row.msgStatus].label }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="时间">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" :disabled="scope.row.msgStatus === 1" @click="allMarkAsRead(scope.row.id)">已读</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          <!-- <el-button type="text" size="small" @click="changePage('detail', scope.row)">查看详情</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="tac mt-3"
      background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- <order-detail ref="detailorder" v-if="page === 'detail'" :id="this.orderId" @goBack="goBack"></order-detail> -->
  </div>
</template>

<script>
  // import OrderDetail from '../order/order-detail'
  import moment from 'moment'
import { checkIsNull } from '../../../../utils'
  export default {
    components: {
      // OrderDetail
    },
    computed: {
      unRead: {
        get () { return this.$store.state.user.unRead },
        set (val) { this.$store.commit('user/updateUnRead', val) }
      }
    },
    data () {
      return {
        dataForm: {
          msgStatus: null,
          msgType: null,
          dateRange: []
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false,
        pickerOptions: {
          shortcuts: [{
            text: '本月',
            onClick (picker) {
              let begin = new Date()
              begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
              let end = new Date()
              picker.$emit('pick', [begin, end])
            }
          }, {
            text: '今年至今',
            onClick (picker) {
              const end = new Date()
              const start = new Date(new Date().getFullYear(), 0)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一年',
            onClick (picker) {
              const end = new Date()
              const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        statusOptions: {
          0: {label: '未读', tag: 'warning'},
          1: {label: '已读', tag: 'success'}
        },
        typeDict: {},
        page: ''
      }
    },
    created () {
      this.initDict()
      // 初始化查询时间范围
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
      this.dataForm.dateRange = [start, end]
    },
    mounted () {
      this.getDataList(true)
    },
    methods: {
      // 初始化字典
      initDict () {
        this.$getDictMap('MSG_TYPE', (dict) => {
          this.typeDict = dict.MSG_TYPE
        })
      },
      // 获取数据列表
      getDataList (reset) {
        this.dataListLoading = true
        if (reset) {
          this.pageIndex = 1
        }
        let [beginDate, endDate] = this.dataForm.dateRange || []
        this.$http({
          url: this.$http.adornUrl(this.$api.MESSAGE.LIST),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'msgStatus': this.dataForm.msgStatus,
            'msgType': this.dataForm.msgType,
            'beginDate': beginDate ? moment(beginDate).format('YYYY-MM-DD') : null,
            'endDate': endDate ? moment(endDate).format('YYYY-MM-DD') : null
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList(true)
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList(false)
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm('确定进行删除操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(this.$api.MESSAGE.DELETE),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList(true)
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      },
      // 标记为已读
      allMarkAsRead (id) {
        this.$http({
          url: this.$http.adornUrl(this.$api.MESSAGE.UPDATE),
          method: 'post',
          params: this.$http.adornParams({'id' : id})
        }).then(({data}) => {
          if (data && data.code === 0) {
            if(checkIsNull(id)) {
              this.unRead = 0
            } else {
              this.unRead = this.unRead - 1
            }
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList(true)
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }
      // 查看详情页面跳转
      // changePage (type, rowData) {
      //   this.id = rowData.id
      //   this.allMarkAsRead(this.id)
      //   this.page = type
      //   if (rowData.msgType === order) {
      //     this.orderId = rowData.dataId
      //   }
      // },
    }
  }
</script>
